import styled from '@emotion/styled';

export const IconInline = styled.div`
  display: inline;
  span {
    display: inline;
    div {
      margin-left: 5px;
      margin-right: 5px;
      display: inline;
    }
  }
`;

export const TableStyles = styled.div`
  padding: 0.25rem;

  @media (min-width: 832px) {
    margin-left: -64px;
    margin-right: -64px;
  }
  table {
    font-size: 12px;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.2rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    .pagination {
      padding: 0.5rem;
    }
  }
`;
